import 'core-js/stable';
import 'react-app-polyfill/ie11';

import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import App from './Containers/App';
import i18n from './translations/i18n';
import registerServiceWorker from './registerServiceWorker';
import store from './store';
import Config from './Config';

import './sass/base.scss';

// Helper to load the Intercom script dynamically
const loadIntercomScript = (src) => new Promise((resolve, reject) => {
  // Check if the script is already in the document to avoid reloading
  if (document.querySelector(`script[src="${src}"]`)) {
    resolve();
    return;
  }

  const script = document.createElement('script');
  script.async = true;
  script.src = src;

  script.onload = () => resolve();
  script.onerror = () => reject(new Error('Failed to load Intercom script'));

  document.head.appendChild(script);
});

// Check if Intercom is already initialized
const isIntercomLoaded = () => typeof window.Intercom === 'function';

// Function to initialize or update Intercom
const initializeOrUpdateIntercom = async (intercomSrc) => {
  const w = window;
  const ic = w.Intercom;

  if (!isIntercomLoaded()) {
    try {
      await loadIntercomScript(intercomSrc);
    } catch (error) {
      return;
    }
  }

  if (typeof ic === 'function') {
    ic('reattach_activator');
    if (w.intercomSettings) {
      ic('update', w.intercomSettings);
    }
  } else {
    // Queue up Intercom commands if not initialized
    const i = function (...args) { i.c(args); };
    i.q = [];
    i.c = function (args) { i.q.push(args); };
    w.Intercom = i;

    if (w.intercomSettings) {
      w.Intercom('boot', w.intercomSettings);
    }
  }
};

// Dynamically load and initialize Intercom based on the environment
const initializeIntercom = async (env) => {
  const intercomSrc = env === 'prod'
    ? 'https://widget.intercom.io/widget/tlmqy4u1'
    : 'https://widget.intercom.io/widget/slathgp8';

  await initializeOrUpdateIntercom(intercomSrc);
};

const AppWithIntercom = () => {
  useEffect(() => {
    // Initialize Intercom only when the component mounts
    initializeIntercom(Config.ENVIRONMENT_NAME);
  }, []);  // Empty dependency array ensures this runs only once on mount

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <App />
        </Provider>
      </I18nextProvider>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <AppWithIntercom />,
  document.getElementById('root')
);


registerServiceWorker();
