import { connect } from 'react-redux';

import AuthModal from '../../Components/AuthModal';
import { authorize } from '../../reducers/auth';
import { getAppConfig } from '../../reducers/appConfig';

const mapStateToProps = (state, ownProps) => ({
  showAuthConfirm: state.auth.showAuthConfirm,
  appConfig: state.appConfig,
  ...ownProps
});

const mapDispatchToProps = (dispatch) => ({
  authorize(...arg) {
    return authorize(dispatch, ...arg);
  },
  getAppConfig(...arg) {
    return getAppConfig(dispatch, ...arg);
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthModal);
